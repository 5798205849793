import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_component_el_form, {
    ref: _ctx.setFormRef,
    model: _ctx.formModel,
    rules: _ctx.formRules,
    onSubmit: _withModifiers(_ctx.handleSubmit, ["prevent"]),
    disabled: _ctx.isDisabled
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form_item, {
        label: _ctx.$t('roleAndPermissionPage.textRoleName'),
        prop: "name"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            name: "name",
            modelValue: _ctx.formModel.name,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formModel.name) = $event))
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }, 8, ["label"]),
      _createVNode(_component_el_row, { justify: "end" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_button, {
            type: "primary",
            plain: "",
            onClick: _ctx.cancel,
            disabled: _ctx.loading
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("common.cancel")), 1)
            ]),
            _: 1
          }, 8, ["onClick", "disabled"]),
          _createVNode(_component_el_button, {
            type: "primary",
            "native-type": "submit",
            loading: _ctx.loading
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("common.save")), 1)
            ]),
            _: 1
          }, 8, ["loading"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model", "rules", "onSubmit", "disabled"]))
}