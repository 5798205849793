
import { computed, defineComponent, ref } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { RoleModalEnum } from "@/store/modules/MasterRoleModule";
import AddNewRoleForm from "@/components/forms/AddNewRoleForm.vue";
import AssignMenuRoleForm from "@/components/forms/AssignMenuRoleForm.vue";
export default defineComponent({
  name: "component-add-new-role-modal",
  components: { AddNewRoleForm, AssignMenuRoleForm },
  setup() {
    const store = useStore();
    const modal = computed({
      get: () => store.getters.roleModal,
      set: (value) => store.dispatch(Actions.UPDATE_MASTER_ROLE_DIALOG, value),
    });
    const steps = ["Add New Role", "Assign Menu to Role"];
    const formStep = ref(0);

    function closeModal() {
      store.dispatch(Actions.UPDATE_MASTER_ROLE_DIALOG, false);
      store.dispatch(
        Actions.UPDATE_MASTER_ROLE_ACTIVE_DIALOG,
        RoleModalEnum.DEFAULT
      );
      store.dispatch(Actions.UPDATE_ROLE_DTL_MENU_STATE, null);
      store.dispatch(Actions.UPDATE_MASTER_ROLE_SELECTED_DETAIL, null);
      store.dispatch(Actions.UPDATE_MASTER_MENU_SUBMITTING, false);
      store.dispatch(Actions.GET_ROLE_LIST);
    }

    function handleNext() {
      if (formStep.value === 2) {
        closeModal();
        return;
      }
      formStep.value += 1;
    }

    function handlePrev() {
      if (formStep.value === 0) {
        closeModal();
        return;
      }
      formStep.value -= 1;
    }

    return {
      steps,
      handleNext,
      handlePrev,
      closeModal,
      modal,
      formStep,
    };
  },
});
