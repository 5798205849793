
import { computed, defineComponent, ref, onMounted } from "vue";
import { useStore } from "vuex";
// import { useI18n } from "vue-i18n";
import { ElMessage } from "element-plus";
import { Actions } from "@/store/enums/StoreEnums";
import { ICroleDetail, RoleModalEnum } from "@/store/modules/MasterRoleModule";
import ApiService from "@/core/services/ApiService";
import * as storeTutorial from "@/store/enums/StoreTutorialEnums";
import { Tutorial } from "@/store/enums/interface_option/tutorial";
import { toastError, toastSuccess } from "@/core/services/Alert";
import { useI18n } from "vue-i18n";

export default defineComponent({
	name: "component-assign-menu-role-form",
	props: ["initialRole"],
	setup(props, context) {
		const store = useStore();
		const loading = computed(() => store.getters.roleLoading);
		const totalPemision = ref(0);
		const activeName = ref("first");
		const submitting = computed(() => store.getters.roleSubmitting);
		const roleDetail = computed(() => store.getters.roleSelectedDetail);
		const roleSelected = computed(() => store.getters.roleSelected);
		const stateAll = ref(false);
		const MenuISolasiData = ref([] as any);
		const { t } = useI18n();
		const dataMenusIsolate = ref([]);
		const page_permissions:ICroleDetail | any = ref({
			role_id: "",
			page_permissions: [
				{
					page_id: "",
					code: "",
					name: "",
					is_active: false,
					permissions: [
						{
							code: "",
							name: "",
							is_active: false
						}
					]
				}
			],
			isolation_permissions: [
				{
					isolation_id: "",
					code: "",
					name: "",
					is_active: false
				}
			]
		})

		const typeSumbit = ref("");
		const handlePermChange = (scope, perm) => {
			if (perm.state && !scope.row.is_active) scope.row.is_active = true;
			if (scope.row.permissions.filter((perm) => perm.state).length === 0) {
				scope.row.is_active = false;
			}
		};
		const handleMenuChange = (scope) => {
			if (!scope.row.is_active) {
				if (scope.row.permissions) {
					scope.row.permissions.forEach((perm) => (perm.is_active = false));
				}
			}
		};

		onMounted(() => {
			getListAccessPage();
		});

		const permissionsList = computed(() => store.getters.roleSelected);

		const fetchPermisions = () => {
			ApiService.query(
				"role/api/v1/permission?page=1&name=&itemsPerPage=100&trash=0",
				{
					params: {
						page: 1,
						itemsPerPage: 100,
						trash: 0,
					},
				}
			).then((ress) => {
				store.commit(storeTutorial.Mutations.FILTER_STEP, {
					name: "edit-rolemember-modal",
					bool: true,
				} as Tutorial.localStorage);
				totalPemision.value = ress.data.data.total;
				if (ress.data.data.total == roleSelected.value.access_count) {
					stateAll.value = true;
				}
			});
		};

		const cancel = () => context.emit("close");
		const next = () => context.emit("next");

		async function updateRole() {
			store.dispatch(Actions.UPDATE_MASTER_ROLE_SUBMITTING, true)
			const payloadUpdate = {
				id: page_permissions.value.role_id,
				page_permissions:  page_permissions.value.page_permissions.map((page) => {
					return {
						page_code: page.code,
						is_active: page.is_active,
						permissions: page.permissions
					}
				}),
				isolation_permissions: page_permissions.value.isolation_permissions.map((isolation) => {
					return {
						isolation_code: isolation.code,
						is_active: isolation.is_active
					}
				}),
			};			
			await store
				.dispatch(Actions.UPDATE_ROLE, payloadUpdate)
				.then((result) => {
					toastSuccess(
						`${t("common.success")} ${t("common.update")} ${t(
							"membersPage.textPeran"
						)}`
					);
					store.dispatch(Actions.GET_PROFILE_DETAIL);
					store.dispatch(Actions.UPDATE_MASTER_ROLE_SUBMITTING, false)
					cancel();
				})
				.catch((errors) => {
					store.dispatch(Actions.UPDATE_MASTER_ROLE_SUBMITTING, false)
					store.dispatch(Actions.GET_PROFILE_DETAIL);
					toastError(
						`${t("common.failed")} ${t("common.update")} ${t(
							"membersPage.textPeran"
						)}`
					);
				});
		}

		const errors = computed(() => store.getters.getErrors);

		const submit = async () => {
			await updateRole();
		};

		const isAssignToNewRole = computed(
			() => store.getters.roleActiveModal === RoleModalEnum.ADD_ROLE
		);

		const getListAccessPage = () => {
			store.dispatch(Actions.GET_ROLE_DETAIL, permissionsList.value ? permissionsList.value._id : undefined).then((data) => {
				page_permissions.value = data.data
			})
		};

		const handleMenuChangeIsolate = (param) => {
			if (dataMenusIsolate.value.includes(param as never)) {
				dataMenusIsolate.value = dataMenusIsolate.value.filter(
					(a) => a != param
				);
			} else {
				dataMenusIsolate.value.push(param as never);
			}
		};
		return {
			handleMenuChangeIsolate,
			typeSumbit,
			totalPemision,
			roleSelected,
			stateAll,
			isAssignToNewRole,
			submitNewRole: submit,
			roleDetail,
			cancel,
			next,
			handleMenuChange,
			handlePermChange,
			loading,
			submitting,
			activeName,
			MenuISolasiData,
			permissionsList,
			page_permissions,
		};
	},
});
