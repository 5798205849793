import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_component_assign_menu_role_form = _resolveComponent("component-assign-menu-role-form")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: _ctx.modal,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modal) = $event)),
    "close-on-click-modal": false,
    "close-on-press-escape": false,
    "show-close": false,
    "destroy-on-close": "",
    width: "70%"
  }, {
    title: _withCtx(() => [
      _createElementVNode("h2", null, _toDisplayString(_ctx.$t("roleAndPermissionPage.textEditRole")), 1)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_component_assign_menu_role_form, {
        onClose: _ctx.handleClose,
        "initial-role": _ctx.initialRole
      }, null, 8, ["onClose", "initial-role"])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}