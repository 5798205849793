import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: _ctx.open,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.open) = $event))
  }, {
    title: _withCtx(() => [
      _createElementVNode("h1", null, _toDisplayString(_ctx.$t("common.delete")) + " " + _toDisplayString(_ctx.$t("roleAndPermissionPage.textRole")) + " " + _toDisplayString(_ctx.role ? _ctx.role.name : ""), 1)
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_el_button, {
        type: "primary",
        disabled: _ctx.isLoading,
        plain: "",
        onClick: _ctx.cancel
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("common.cancel")), 1)
        ]),
        _: 1
      }, 8, ["disabled", "onClick"]),
      _createVNode(_component_el_button, {
        type: "primary",
        loading: _ctx.isLoading,
        onClick: _ctx.submit
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("common.delete")), 1)
        ]),
        _: 1
      }, 8, ["loading", "onClick"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("p", null, _toDisplayString(_ctx.$t("roleAndPermissionPage.textConfirmDelete")), 1)
    ]),
    _: 1
  }, 8, ["modelValue"]))
}