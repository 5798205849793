
import { computed, defineComponent, ref } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { ElForm } from "element-plus";
import ComponentAssignMenuRoleForm from "@/components/forms/AssignMenuRoleForm.vue";
import { RoleModalEnum } from "@/store/modules/MasterRoleModule";
import { setAllertError } from "@/core/services/Alert";

export default defineComponent({
  name: "component-edit-access-modal",
  components: { ComponentAssignMenuRoleForm },
  setup() {
    const store = useStore();
    const modal = computed({
      get: () => store.getters.roleModal,
      set: () => store.dispatch(Actions.UPDATE_MASTER_ROLE_DIALOG, true),
    });
    const formRef = ref<InstanceType<typeof ElForm>>();
    const setFormRef = (el: InstanceType<typeof ElForm>) => {
      formRef.value = el;
    };
    const errors = computed(() => store.getters.getErrors);
    const selectedRole = computed(() => store.getters.roleSelected);
    const { _id } = selectedRole.value;
    const initialRole = ref(null);
    // store
    //   .dispatch(Actions.GET_ROLE_DETAIL, _id)
    //   .then((result) => {
    //     const data = result.data;
    //     // for table consistency
    //     data.page_id = data.page_id.sort(
    //       (a, b) => a.menu_id - b.menu_id
    //     );
    //     return store.dispatch(Actions.UPDATE_MASTER_ROLE_SELECTED_DETAIL, data);
    //   })
    //   .then(() => {
    //     initialRole.value = JSON.parse(
    //       JSON.stringify(store.getters.roleSelectedDetail)
    //     );
    //   })
    //   .catch(() => {
    //     setAllertError(errors.value);
    //   });

    function handleClose() {
      store.dispatch(Actions.UPDATE_MASTER_ROLE_DIALOG, false);
      store.dispatch(
        Actions.UPDATE_MASTER_ROLE_ACTIVE_DIALOG,
        RoleModalEnum.DEFAULT
      );
      store.dispatch(Actions.UPDATE_ROLE_DTL_MENU_STATE, null);
      store.dispatch(Actions.UPDATE_MASTER_ROLE_SELECTED_DETAIL, null);
      store.dispatch(Actions.UPDATE_MASTER_MENU_SUBMITTING, false);
      store.dispatch(Actions.GET_ROLE_LIST);
    }

    return {
      initialRole,
      handleClose,
      selectedRole,
      setFormRef,
      modal,
    };
  },
});
