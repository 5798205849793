
import { computed, defineComponent, reactive, ref } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { ElForm, ElMessage } from "element-plus";
import { Actions } from "../../store/enums/StoreEnums";
import { RoleModalEnum } from "@/store/modules/MasterRoleModule";
import { toastError } from "@/core/services/Alert";
export default defineComponent({
  name: "component-add-new-role-form",
  setup(props, context) {
    const store = useStore();
    const { t } = useI18n();
    const formRef = ref<InstanceType<typeof ElForm>>();
    const setFormRef = (el: InstanceType<typeof ElForm>) => {
      formRef.value = el;
    };
    const formModel = reactive({
      name: "",
      code: "",
    });
    const formRules = reactive({
      name: [
        {
          required: true,
          message: `${t("roleAndPermissionPage.textRequired", {
            attr: t("roleAndPermissionPage.textRoleName"),
          })}`,
          trigger: "blur",
        },
      ],
    });
    const isDisabled = ref(false);

    function handleSubmit() {
      const payload = {
        name: formModel.name,
        code: formModel.name.replace(/ /g, '-'),
        page_permissions: [],
        isolation_permissions: [],
      };
      formRef?.value?.validate(async (valid) => {
        if (valid) {
          store
            .dispatch(Actions.STORE_ROLE, payload)
            .then(({ success, data }) => {
              if (success) {
                store.dispatch(Actions.UPDATE_MASTER_ROLE_ACTIVE_DIALOG, RoleModalEnum.EDIT_ACCESS);
                store.dispatch(Actions.UPDATE_MASTER_ROLE_DIALOG, true);
                store.dispatch(Actions.UPDATE_MASTER_ROLE_SELECTED, data);
              }
            })
          .then((roleDetailRes: any) => {
            store.dispatch(
              Actions.UPDATE_MASTER_ROLE_SELECTED_DETAIL,
              roleDetailRes?.data
            );
            isDisabled.value = true;
            context.emit("next");
          })
          .catch((error) => {
            toastError(`${t('common.failed')} ${t('common.create')} ${t('membersPage.textPeran')}`)
          });

          

          
        }
      });
    }

    function cancel() {
      context.emit("prev");
    }

    const loading = computed(() => store.getters.roleLoading);

    return {
      isDisabled,
      cancel,
      formRef,
      setFormRef,
      formModel,
      formRules,
      handleSubmit,
      loading,
    };
  },
});
