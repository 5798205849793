
import { computed, defineComponent, ref } from "vue";
import { useStore } from "vuex";
import { ElMessage } from "element-plus";
import { Actions } from "@/store/enums/StoreEnums";
import { RoleModalEnum } from "@/store/modules/MasterRoleModule";

export default defineComponent({
  name: "component-delete-role-modal",
  setup() {
    const store = useStore();
    const isLoading = ref(false);
    const open = computed({
      get: () =>
        store.getters.roleModal &&
        store.getters.roleActiveModal === RoleModalEnum.DELETE_ROLE,
      set: (val) => {
        if (val)
          store.dispatch(
            Actions.UPDATE_MASTER_ROLE_ACTIVE_DIALOG,
            RoleModalEnum.DELETE_ROLE
          );
        else {
          store.dispatch(
            Actions.UPDATE_MASTER_ROLE_ACTIVE_DIALOG,
            RoleModalEnum.DEFAULT
          );
          store.dispatch(Actions.UPDATE_MASTER_ROLE_SELECTED, null);
        }
        store.dispatch(Actions.UPDATE_MASTER_ROLE_DIALOG, val);
      },
    });

    const role = computed(() => store.getters.roleSelected);

    async function submit() {
      isLoading.value = true;
      const { _id } = role.value;
      await store
        .dispatch(Actions.DESTROY_ROLE, _id)
        .then((result) => {
          ElMessage({
            message: result.msg,
            type: "success",
          });
        })
        .then(() => {
          return store.dispatch(Actions.GET_ROLE_LIST);
        })
        .then(() => {
          cancel();
        })
        .catch((error) => {
          ElMessage({
            message: error,
            type: "error",
          });
        })
        .finally(() => (isLoading.value = false));
    }

    function cancel() {
      store.dispatch(
        Actions.UPDATE_MASTER_ROLE_ACTIVE_DIALOG,
        RoleModalEnum.DEFAULT
      );
      store.dispatch(Actions.UPDATE_MASTER_ROLE_SELECTED, null);
      store.dispatch(Actions.UPDATE_MASTER_ROLE_DIALOG, false);
    }

    return {
      role,
      open,
      isLoading,
      cancel,
      submit,
    };
  },
});
