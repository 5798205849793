import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-408826ba"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ebcf_modal" }
const _hoisted_2 = { id: "add-role-modal-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_step = _resolveComponent("el-step")!
  const _component_el_steps = _resolveComponent("el-steps")!
  const _component_add_new_role_form = _resolveComponent("add-new-role-form")!
  const _component_assign_menu_role_form = _resolveComponent("assign-menu-role-form")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_dialog, {
        modelValue: _ctx.modal,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modal) = $event)),
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "show-close": false,
        "destroy-on-close": "",
        width: "70%"
      }, {
        title: _withCtx(() => [
          _createElementVNode("h2", null, _toDisplayString(_ctx.steps[_ctx.formStep]), 1)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_el_steps, { active: _ctx.formStep }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.steps, (step, index) => {
                return (_openBlock(), _createBlock(_component_el_step, {
                  key: index,
                  title: step
                }, null, 8, ["title"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["active"]),
          _withDirectives(_createVNode(_component_add_new_role_form, {
            class: "mt-5",
            onNext: _ctx.handleNext,
            onPrev: _ctx.handlePrev,
            onClose: _ctx.closeModal
          }, null, 8, ["onNext", "onPrev", "onClose"]), [
            [_vShow, _ctx.formStep === 0]
          ]),
          _withDirectives(_createVNode(_component_assign_menu_role_form, {
            class: "mt-5",
            onNext: _ctx.handleNext,
            onPrev: _ctx.handlePrev,
            onClose: _ctx.closeModal
          }, null, 8, ["onNext", "onPrev", "onClose"]), [
            [_vShow, _ctx.formStep === 1]
          ])
        ]),
        _: 1
      }, 8, ["modelValue"])
    ])
  ]))
}