
import {
  computed,
  reactive,
  ref,
  defineComponent,
  onMounted,
  watch,
} from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { ElForm, ElMessage } from "element-plus";
import { RoleModalEnum } from "@/store/modules/MasterRoleModule";
import { hideModal } from "@/core/helpers/dom";
import { errorMonitor } from "events";

export default defineComponent({
  name: "component-add-member-to-role-modal",
  emits: ["success", "close"],
  setup(props, { emit }) {
    const store = useStore();
    const modal = computed({
      get: () => store.getters.roleModal,
      set: (val) => store.dispatch(Actions.UPDATE_MASTER_ROLE_DIALOG, val),
    });

    function closeDialog() {
      formRef?.value?.resetFields();
      formRef?.value?.clearValidate();
      store.dispatch(Actions.UPDATE_MASTER_ROLE_DIALOG, false);
      store.dispatch(
        Actions.UPDATE_MASTER_ROLE_ACTIVE_DIALOG,
        RoleModalEnum.DEFAULT
      );
      hideModal(newTargetModalRef.value);
    }

    const formRef = ref<InstanceType<typeof ElForm>>();
    const newTargetModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const setFormRef = (el: InstanceType<typeof ElForm>) => {
      formRef.value = el;
    };
    const formModel = reactive({
      members: [],
    });
    const formRules = reactive({
      members: [
        {
          required: true,
          message: `This field is required`,
          trigger: "blur",
        },
      ],
    });
    const isSubmitting = ref(false);

    async function handleSubmit() {
      isSubmitting.value = true;
      Promise.all(
        formModel.members.map((member) =>
          store.dispatch(Actions.ASSIGN_USER_ROLE, {
            id_user: member,
            id_role: store.getters.roleSelected._id,
          })
        )
      )
        .then((res) => {
          res.forEach((r, index) => {
            if (r.success) {
              setTimeout(() => {
                ElMessage({
                  message: r.msg,
                  type: "success",
                });
              }, index * 3500);
              closeDialog();
              emit("success");
            }
          });
        })
        .catch((error) => {
          ElMessage({
            message: error.msg,
            type: "success",
          });
          console.log(error)
        })
        .finally(() => (isSubmitting.value = false));
    }

    function clearForm(done) {
      formRef.value?.clearValidate();
      formRef.value?.resetFields();
      done();
    }

    const users = ref([]);
    const userList = computed(() => store.getters.roleSelected);
    const alreadyInvitedMember = computed(() => {
      if (userList.value && userList.value.users) {
        return userList.value.users.map((user) => user.user_id);
      } else {
        return [];
      }
    });
    watch(
      () => alreadyInvitedMember.value,
      async () => {
        await search("");
        if (alreadyInvitedMember.value) {
          users.value = store.getters.getUsers
            .map((user) => user)
            .filter((user) => !alreadyInvitedMember.value.includes(user.id));
        }
      }
    );
    watch(
      () => store.getters.getUsers,
      (newValue) => {
        if (alreadyInvitedMember.value) {
          users.value = newValue?.filter(
            (user) => !alreadyInvitedMember?.value.includes(user.id)
          );
        }
      }
    );
    let pagination = reactive({});
    
    const keyword = computed({
      get: () => store.getters.usersFilter.search,
      set: (val) => store.dispatch(Actions.UPDATE_USERS_KEYWORD, val),
    });

    onMounted(() => {
      store.dispatch(Actions.GET_USER_LIST, store.getters.usersFilter).then((response) => {
        pagination = response.data;
      });
    });

    const search = async (query) => {
      loading.value = true;
      console.log(query);
      
      keyword.value = query
      await store
        .dispatch(Actions.GET_USER_LIST, store.getters.usersFilter)
        .then((response) => (pagination = response.data))
        .finally(() => (loading.value = false));
    };

    function handleModalClick() {
      emit("close");
    }

    return {
      handleModalClick,
      alreadyInvitedMember,
      newTargetModalRef,
      pagination,
      loading,
      search,
      users,
      clearForm,
      setFormRef,
      modal,
      formModel,
      formRules,
      closeDialog,
      handleSubmit,
    };
  },
});
