import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, withModifiers as _withModifiers, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "modal-dialog modal-dialog-centered mw-650px" }
const _hoisted_2 = {
  class: "modal-content rounded",
  id: "open-invite-member-modal"
}
const _hoisted_3 = { class: "modal-header pb-0 border-0 justify-content-end" }
const _hoisted_4 = {
  class: "btn btn-sm btn-icon btn-active-color-primary",
  "data-bs-dismiss": "modal"
}
const _hoisted_5 = { class: "svg-icon svg-icon-1" }
const _hoisted_6 = { class: "modal-body scroll-y px-10 px-lg-15 pt-0 pb-15" }
const _hoisted_7 = { class: "text-center" }
const _hoisted_8 = { class: "d-flex flex-column fv-row" }
const _hoisted_9 = { class: "d-flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock("div", {
    class: "modal fade",
    id: "kt_modal_new_target",
    ref: "newTargetModalRef",
    tabindex: "-1",
    "aria-hidden": "true",
    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleModalClick && _ctx.handleModalClick(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("span", _hoisted_5, [
              _createVNode(_component_inline_svg, { src: "/media/icons/duotune/arrows/arr061.svg" })
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("h1", null, _toDisplayString(_ctx.$t("roleAndPermissionPage.btnInviteMember")), 1)
          ]),
          _createVNode(_component_el_form, {
            class: "form",
            onSubmit: _withModifiers(_ctx.handleSubmit, ["prevent"]),
            model: _ctx.formModel,
            rules: _ctx.formRules,
            ref: _ctx.setFormRef,
            "label-position": "top"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_el_form_item, {
                  label: _ctx.$t('roleAndPermissionPage.textMembers'),
                  prop: "members"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_select, {
                      name: "members",
                      id: "members",
                      modelValue: _ctx.formModel.members,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formModel.members) = $event)),
                      filterable: "",
                      remote: "",
                      "remote-method": _ctx.search,
                      multiple: "",
                      class: "w-100"
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.users, (user) => {
                          return (_openBlock(), _createBlock(_component_el_option, {
                            key: user._id,
                            label: user.name,
                            value: user._id
                          }, null, 8, ["label", "value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["modelValue", "remote-method"])
                  ]),
                  _: 1
                }, 8, ["label"])
              ])
            ]),
            _: 1
          }, 8, ["onSubmit", "model", "rules"]),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_el_button, {
              type: "primary",
              plain: "",
              class: "w-50",
              onClick: _ctx.closeDialog
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("common.cancel")), 1)
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_el_button, {
              type: "primary",
              class: "w-50",
              loading: _ctx.loading,
              onClick: _ctx.handleSubmit
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("roleAndPermissionPage.btnAddMember")), 1)
              ]),
              _: 1
            }, 8, ["loading", "onClick"])
          ])
        ])
      ])
    ])
  ], 512))
}