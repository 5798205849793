
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { computed, inject, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { Actions } from "@/store/enums/StoreEnums";
import AddNewTeamDrawer from "@/components/modals/AddNewTeamDrawer.vue";
import { useStore } from "vuex";
import { RoleModalEnum } from "@/store/modules/MasterRoleModule";
import AddMemberToRoleModal from "@/components/modals/AddMemberToRoleModal.vue";
import EditAccessModal from "@/components/modals/EditAccessModal.vue";
import AddNewRoleModal from "@/components/modals/AddNewRoleModal.vue";
import ComponentDeleteRoleModal from "@/components/modals/DeleteRoleModal.vue";
import { setAllertError } from "@/core/services/Alert";
import { detectSubDomain, checkPermission } from "@/core/helpers/helps";
import { images } from "@/assets/ts";
import { ElMessage } from "element-plus";

export default {
	name: "role-and-permission",
	components: {
		AddMemberToRoleModal,
		EditAccessModal,
		AddNewRoleModal,
		ComponentDeleteRoleModal,
	},
	setup() {
		const store = useStore();
		const { t } = useI18n();
		const getUserLogin = ref(JSON.parse(localStorage.getItem("user") as any));
		const errors = computed(() => {
			return store.getters.getErrors;
		});
		const images: any = inject("$images");
    const userProfile = store.getters.getUserProfile

		setCurrentPageBreadcrumbs(("rolePermission"), [
			{ title: ("common.setting"), path: "/setting" },
			{
				title: ("organization"),
				path: "/setting/organization/general-setting",
			},
		]);

		const detailData = computed(() => store.getters.roleData);
		onMounted(async () => {
			await fetchData();
		});

		async function fetchData() {
			await store.dispatch(Actions.GET_ROLE_LIST).catch(() => {
				setAllertError(errors.value);
			});
		}

		function openDrawer() {
			store.dispatch(Actions.UPDATE_MASTER_ROLE_DRAWER, true);
		}

		const activeModal = computed(() => store.getters.roleActiveModal);
		const openModal = (selectedRow, modalName) => {
			cleanUp();
			store.dispatch(Actions.UPDATE_MASTER_ROLE_ACTIVE_DIALOG, modalName);
			store.dispatch(Actions.UPDATE_MASTER_ROLE_DIALOG, true);
			store.dispatch(Actions.UPDATE_MASTER_ROLE_SELECTED, selectedRow);
		};

		function cleanUp() {
			store.dispatch(Actions.UPDATE_ROLE_DTL_MENU_STATE, null);
			store.dispatch(Actions.UPDATE_MASTER_ROLE_SELECTED_DETAIL, null);
			store.dispatch(Actions.UPDATE_MASTER_MENU_SUBMITTING, false);
		}

		const paginationData = computed(() => {
			return {
				current:
					store.getters.roleFilter.page > 1
						? store.getters.roleFilter.page + store.getters.roleFilter.limit - 1
						: 1,
				to: store.getters.roleFilter.limit * store.getters.roleFilter.page,
				...store.getters.roleFilter,
			};
		});

		const headerCellClassName = () => {
			return "bg-secondary greyHeader";
		};

		const detachUser = (id_user, id_role) => {
			const payload = {
				id_user,
				id_role,
			};

			store
				.dispatch(Actions.UNASSIGN_USER_ROLE, payload)
				.then((result) => {
					ElMessage({
						message: result.msg,
						type: "success",
					});

					fetchData();
				})
				.catch((error) => {
					ElMessage({
						message: error,
						type: "error",
					});
				});
		};

		return {
			getUserLogin,
			detectSubDomain,
			fetchData,
			headerCellClassName,
			openModal,
			RoleModalEnum,
			activeModal,
			Actions,
			store,
			openDrawer,
			detailData,
			images,
			detachUser,
			paginationData,
			checkPermission,
      userProfile,
		};
	},
};
